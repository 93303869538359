<template>
  <div class="page-wrapper section-space--inner--60">
    <!--Contact section start-->
    <div class="conact-section">
      <div class="container">
        <div class="row section-space--bottom--50">
          <div class="col">
            <!-- google map component -->
            <GoogleMap />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-12" style="padding: 30px 0;">
            <div class="contact-information">
              <h3>{{ data.contactInfo.title }}</h3>
              <ul>
                <li>
                  <span class="icon">
                    <i :class="data.contactInfo.address.icon"></i>
                  </span>
                  <span class="text">{{
                    data.contactInfo.address.location
                  }}</span>
                </li>
                <li>
                  <span class="icon">
                    <i :class="data.contactInfo.telephone.icon"></i>
                  </span>
                  <span class="text">
                    <a href="tel:+584142698744">{{
                      data.contactInfo.telephone.no_1
                    }}</a>
                  </span>
                </li>
                <li>
                  <span class="icon">
                    <i :class="data.contactInfo.mail.icon"></i>
                  </span>
                  <span class="text">
                    <a :href="'mailto:' + data.contactInfo.mail.email">{{
                      data.contactInfo.mail.email
                    }}</a>
                    <a :href="data.contactInfo.mail.web">{{
                      data.contactInfo.mail.web
                    }}</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-8 col-12">
            <ContactForm :platform="12" />
          </div>
        </div>
      </div>
    </div>
    <!--Contact section end-->
  </div>
</template>

<script>
import axios from "axios";
import data from "../../data/contact.json";
import GoogleMap from "@/components/GoogleMap";
import ContactForm from "@/components/ContactForm";
export default {
  components: {
    GoogleMap,
    ContactForm
  },
  created() {},
  methods: {},
  data() {
    return {
      data
    };
  }
};
</script>