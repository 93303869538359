<template>
<div class="google-map-area section" id="support">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62769.83666887628!2d-66.89909922432183!3d10.491616267254095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a58fdafcdd757%3A0xd2bf02cc07ec0ce7!2sTorre%20Europa%2C%20Avenida%20Francisco%20de%20Miranda%2C%20Caracas%201060%2C%20Miranda!5e0!3m2!1ses-419!2sve!4v1602790619249!5m2!1ses-419!2sve" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="true" aria-hidden="false" tabindex="0"></iframe>
  <GmapMap :center="{lat:10.489246, lng:-66.864756}" :zoom="12" map-type-id="roadmap"></GmapMap>
</div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB2D8wrWMY3XZnuHO6C31uq90JiuaFzGws"
  }
});
export default {
  name: "GoogleMap"
};
</script>
